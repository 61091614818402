// prettier-ignore
export const ACTIVITIES = [
       {id: 'WOC',	en:'A spectacular city trip',                     nl:'Een spectaculaire stedentrip ',	      fr: 'Un séjour spectaculaire en ville'},
       {id: 'BEA',	en:'A sunny beach holiday',	                      nl:'Een zonnige strandvakantie',	        fr: 'Des vacances ensoleillées à la plage'},
       {id: 'ART',	en:'An inspiring artistic & cultural experience', nl:'Een kunst & cultuur beleving',	      fr: 'Une expérience artistique et culturelle'},
       {id: 'ACT',	en:'An active outdoor journey',	                  nl:'Een actieve outdoor reis',	          fr: 'Un voyage actif en plein air'},
       {id: 'NAT',	en:'An immersive nature break',	                  nl:'Een onderdompeling in de natuur',     fr: 'Une immersion dans la nature'},
    // {id: 'ARC',	en:'Architecture',	                              nl:'Architectuur',	                      fr: 'Architecture'},
    // {id: 'DIV',	en:'Diving & Snorkelling',                        nl:'Duiken & Snorkelen',	                fr: 'Plongée sous-marine'},
    // {id: 'CUL',	en:'Culinary',	                                  nl:'Culinair',	                          fr: 'Gastronomie'},
    // {id: 'HIS',	en:'History',	                                    nl:'Geschiedenis',      	                fr: 'Histoire'},
    // {id: 'FAM',	en:'Family',	                                    nl:'Familie',	                            fr: 'Famille'},
    // {id: 'GOL',	en:'Golf',	                                      nl:'Golf',	                              fr: 'Golf'},
    // {id: 'LUX',	en:'Luxury',	                                    nl:'Luxe',	                              fr: 'Luxe'},
    // {id: 'MUS',	en:'Nightlife',	                                  nl:'Uitgaan',	                            fr: 'Vie nocturne'},
    // {id: 'ROM',	en:'Romantic',	                                  nl:'Romantiek',                           fr: 'Séjours romantiques'},
    // {id: 'SUR',	en:'Surfing',	                                    nl:'Surfen',	                            fr: 'Le surf'},
    // {id: 'SHO',	en:'Shopping',	                                  nl:'Shoppen',	                            fr: 'Lèche-vitrine'},
    // {id: 'SKI',	en:'Skiing',	                                    nl:'Skiën',	                              fr: 'Le ski'},
    // {id: 'RUN',	en:'Running',	                                    nl:'Running',           	                fr: 'La course'},
];
