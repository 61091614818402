import React from "react";
import styled from "styled-components";
import { device, labelStyles } from "../globalStyles";

const Container = styled.div`
  color: var(--dark-blue-3);
  margin-bottom: 40px;
  text-align: center;
  @media (${device.minTablet}) {
    margin-bottom: 32px;
  }
`;

const Label = styled.label`
  ${labelStyles};
`;

const Subtitle = styled.p`
  margin-top: 16px;
`;

type PageTitleProps = {
  label: string;
  title: string;
  subtitle?: string;
  className?: string;
};

const PageTitle = ({ label, title, subtitle, className }: PageTitleProps) => {
  return (
    <Container className={className}>
      <Label>{label}</Label>
      <h2>{title}</h2>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </Container>
  );
};

export default PageTitle;
