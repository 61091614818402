import React from "react";
import styled from "styled-components";

import ACT from "../svg/activities/ACT.svg";
import ART from "../svg/activities/ART.svg";
import NAT from "../svg/activities/NAT.svg";
import BEA from "../svg/activities/BEA.svg";
import WOC from "../svg/activities/WOC.svg";
import ErrorIcon from "../svg/error.svg";

import { device, labelStyles } from "../globalStyles";

const Container = styled.div`
  --horizontal-padding: 24px;
  @media (${device.minTablet}) {
    --horizontal-padding: 32px;
  }
`;

const CheckboxLabel = styled.label`
  padding: 8px var(--horizontal-padding);
  border: solid 1px var(--light-blue);
  border-radius: 50px;
  color: var(--dark-blue-3);
  user-select: none;
  display: flex;
  cursor: pointer;

  &:hover {
    border-color: var(--blue);
  }

  svg {
    margin-right: 16px;
  }

  @media (min-height: 720px) {
    padding: 16px var(--horizontal-padding);
  }

  @media (min-height: 760px) and (${device.minTablet}) and (max-width: 1439px),
    (min-height: 780px) and (${device.minLaptopL}) {
    padding: 24px var(--horizontal-padding);
  }
`;

const Input = styled.input`
  opacity: 0;
  position: absolute;

  &:checked {
    & + ${CheckboxLabel} {
      background: var(--blue);
      color: white;

      path {
        fill: white;
      }
    }
  }

  &:focus-visible {
    & + ${CheckboxLabel} {
      border-color: var(--blue);
    }
  }
`;

const Error = styled.p`
  ${labelStyles};
  color: var(--red);
  margin-top: 4px;
  margin-left: calc(var(--horizontal-padding) + 1px);
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }
`;

const Icons = {
  ACT,
  ART,
  BEA,
  NAT,
  WOC,
};

type SelectActivityProps = {
  error?: string;
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

const SelectActivity = ({
  label,
  className,
  error,
  ...props
}: SelectActivityProps) => {
  const Icon = Icons[props.id];
  return (
    <Container className={className}>
      <Input type="checkbox" {...props} />
      <CheckboxLabel htmlFor={props.id}>
        <Icon />
        {label}
      </CheckboxLabel>
      {error && (
        <Error>
          <ErrorIcon />
          {error}
        </Error>
      )}
    </Container>
  );
};

export default SelectActivity;
